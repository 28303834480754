






















































































































































































































































































































































import Confirm from '@/components/confirmation/Confirmation.vue';
import JobStatusIcon from '@/components/job-status-icon/JobStatusIcon.vue';
import DomainWorkspaceToolbar from '@/components/navigation/DomainWorkspaceToolbar.vue';
import RefreshButton from '@/components/navigation/RefreshButton.vue';
import { DomainModel, useDomainApi } from '@/module/api/domain';
import { ExportModel, getTotalObjects, useExportApi } from '@/module/api/export';
import { LoadJobModel, getFriendlyLoadJobTypeName, useLoadJobApi } from '@/module/api/load-job';
import router from '@/router';
import { formatDbDate, formatNumber, useFileUtils } from '@/utils';
import DomoOperationChanger from '@/views/app/domo/DomoOperationChanger.vue';
import ImportJobDetailProvisioningComparison from '@/views/app/import/ImportJobDetailProvisioningComparison.vue';
import { computed, defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import Vue from 'vue';
import { createToastInterface } from 'vue-toastification';

const { createFileDownloadElement } = useFileUtils();
const toast = createToastInterface({ maxToasts: 3 });
const {
  getItem: getDomain,
  selectedItem: selectedDomain,
  isLoading: isDomainLoading,
  emptyDomainModel,
  refreshItem: refreshDomain,
} = useDomainApi();

const {
  getItem: getExportJob,
  emptyExportModel,
  selectedItem: selectedExportJob,
  refreshItem: refreshExportJob,
} = useExportApi();

const {
  items: loadJobs,
  getItem: getLoadJob,
  selectedItem: selectedLoadJob,
  isLoading: isLoadJobLoading,
  updateItem: updateLoadJob,
  runLoadJob,
  downloadManifestReport,
  refreshItem: refreshLoadJob,
} = useLoadJobApi();

const emptyState = () => {
  return {
    domainUuid: '',
    loadJobUuid: '',
    isDownloadingManifestReport: false,
    notificationEmail: '',
    validNotificationForm: false,
    migrationSetupStep: 1,
    migrationSetupStepsCompleted: 0,
    isPreparing: false,
    isLoading: false,
    haveReviewedManifest: false,
    havePreparedJob: false,
    haveConfiguredEmail: false,
    haveRunJob: false,
  };
};

const state = reactive(emptyState());

const sourceDomain = ref<DomainModel>(emptyDomainModel());
const sourceExportJob = ref<ExportModel>(emptyExportModel());

const refreshSourceExportJob = async (uuid: string, forceRefresh = false): Promise<void> => {
  Vue.$log.debug(`Refreshing refreshSourceExportJob ${uuid} with force refresh ${forceRefresh}`);
  if (forceRefresh || sourceExportJob?.value?.uuid !== uuid) {
    const result = await getExportJob(uuid);
    if (!result) {
      return;
    }
    sourceExportJob.value = result;
  }
};

const refreshSourceDomain = async (uuid: string, forceRefresh = false): Promise<void> => {
  Vue.$log.debug(`Refreshing refreshSourceDomain ${uuid} with force refresh ${forceRefresh}`);
  if (forceRefresh || sourceDomain?.value?.uuid !== uuid) {
    if (!uuid) {
      sourceDomain.value = emptyDomainModel();
    } else {
      sourceDomain.value = await getDomain(uuid);
    }
  }
};

const downloadLoadJobManifestReport = async () => {
  if (state.isDownloadingManifestReport) {
    return;
  }
  state.isDownloadingManifestReport = true;
  const report = await downloadManifestReport(selectedLoadJob.value?.uuid);
  const downloadElement = createFileDownloadElement({
    document,
    blobData: report,
    fileName: 'load-job-manifest-report-detail.csv',
  });

  if (downloadElement?.click) {
    downloadElement.click();
    toast.success('Downloaded report');
    state.isDownloadingManifestReport = false;
  } else {
    toast.error('Unable to download report');
    state.isDownloadingManifestReport = false;
  }
};

const proceedWithNotificationEmail = async () => {
  state.isLoading = true;
  const updateLoadJobParams: Partial<LoadJobModel> = {
    uuid: selectedLoadJob.value?.uuid,
    notificationEmail: state.notificationEmail,
  };

  const updateLoadJobResponse = await updateLoadJob(updateLoadJobParams);
  if (!updateLoadJobResponse || updateLoadJobResponse < 200 || updateLoadJobResponse > 299) {
    Vue.$log.debug('AFTER UPDATING LOAD JOB ************ ', updateLoadJobResponse);
    const errMessage = 'Unable to update notification settings, so we are not able to proceed.';
    toast.error(errMessage);
    return;
  }
  (state.migrationSetupStep = 4), (state.migrationSetupStepsCompleted = 3);
  state.isLoading = false;
  state.haveConfiguredEmail = true;
};

const runLoadJobWithConfirmation = async (): Promise<void> => {
  const runLoadJobResponse = await runLoadJob(selectedLoadJob.value?.uuid);
  if (runLoadJobResponse && (runLoadJobResponse >= 200 || runLoadJobResponse <= 299)) {
    await refreshDomain(selectedDomain.value.uuid, true);
    await refreshLoadJob(selectedLoadJob.value.uuid, true);
    toast.success('Successfully started job.');
    (state.migrationSetupStepsCompleted = 4), (state.isPreparing = false);
    state.haveRunJob = true;
  } else {
    toast.error('Invalid response running the job.');
  }
};

const initializeData = async (forceRefresh = false) => {
  if (!state.domainUuid || !state.loadJobUuid) {
    const errMessage = 'Unable to initialize table without valid domain and job uuid';
    Vue.$log.error(errMessage);
    return;
  }

  // We refresh the Domain for the workspace and the selected Load Job.
  await refreshDomain(state.domainUuid, forceRefresh);
  await refreshLoadJob(state.loadJobUuid, forceRefresh);

  // Source = the current domain, and the specified source extract job
  await refreshSourceDomain(selectedLoadJob.value?.source?.uuid, forceRefresh);
  await refreshSourceExportJob(selectedLoadJob.value?.source?.extractJobUuid, forceRefresh);

  // Destination = the selected destination
  // We already refreshed this domain.
  await refreshExportJob(selectedLoadJob.value?.destination?.extractJobUuid, forceRefresh);
};

const reset = async () => {
  Object.assign(state, emptyState());
};

const goToLoadJobList = () => {
  router.push({ name: 'WorkspaceImportJobList', params: { uuid: selectedDomain.value?.uuid } });
};

const goToLoadJobDetail = () => {
  router.push({
    name: 'WorkspaceImportJobDetail',
    params: { uuid: selectedDomain.value?.uuid, jobUuid: selectedLoadJob.value?.uuid },
  });
};

const rules = {
  required: [(v: string) => !!v || 'Required.'],
  email: [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    (v: string) => /^[^\s]+/.test(v) || 'E-mail cannot have leading whitespace',
    (v: string) => /[^\s]+$/.test(v) || 'E-mail cannot have trailing whitespace',
  ],
};

export default defineComponent({
  name: 'ImportJobRunWizard',
  components: {
    RefreshButton,
    Confirm,
    DomainWorkspaceToolbar,
    DomoOperationChanger,
    JobStatusIcon,
    ImportJobDetailProvisioningComparison,
  },
  props: {
    uuid: {
      type: String,
    },
    jobUuid: {
      type: String,
    },
  },
  setup(props) {
    reset();
    state.domainUuid = props?.uuid || '';
    state.loadJobUuid = props?.jobUuid || '';
    initializeData();
    return {
      ...toRefs(state),
      formatDbDate,
      formatNumber,
      goToLoadJobDetail,
      loadJobs,
      runLoadJobWithConfirmation,
      proceedWithNotificationEmail,
      getTotalObjects,
      getFriendlyLoadJobTypeName,
      downloadLoadJobManifestReport,
      backLink: `/domain/${props.uuid}/job/import/${props.jobUuid}`,
      goToLoadJobList,
      selectedDomain,
      selectedLoadJob,
      sourceDomain,
      sourceExportJob,
      selectedExportJob,
      rules,
      isLoading: computed(() => isDomainLoading.value || isLoadJobLoading.value),
    };
  },
});
