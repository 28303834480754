var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("domain-workspace-toolbar", {
                attrs: {
                  backLink: _vm.backLink,
                  subtitle:
                    _vm.getFriendlyLoadJobTypeName(_vm.selectedLoadJob.type) +
                    " Job",
                  subsubtitle: "Run"
                },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function() {
                      return [
                        !_vm.haveRunJob
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  plain: "",
                                  title:
                                    "Cancelling may lose some progress, but its still available in the job list"
                                },
                                on: { click: _vm.goToLoadJobDetail }
                              },
                              [_vm._v(" Cancel ")]
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-4" },
        [
          _c("v-card-title", [_vm._v(" " + _vm._s(_vm.selectedLoadJob.label))]),
          _c(
            "v-card-text",
            [
              _c(
                "v-stepper",
                {
                  staticClass: "elevation-0",
                  attrs: { vertical: "" },
                  model: {
                    value: _vm.migrationSetupStep,
                    callback: function($$v) {
                      _vm.migrationSetupStep = $$v
                    },
                    expression: "migrationSetupStep"
                  }
                },
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: { complete: _vm.migrationSetupStep > 1, step: "1" }
                    },
                    [_vm._v(" Review Job ")]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _vm.selectedLoadJob.type === "IMPORT"
                            ? _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          attrs: {
                                            height: "100%",
                                            isLoading: _vm.isLoading
                                          }
                                        },
                                        [
                                          _c("v-card-title", [
                                            _vm._v("Source")
                                          ]),
                                          _c("v-card-text", [
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "overline font-weight-black"
                                                },
                                                [_vm._v("Domain")]
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                { staticClass: "body-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.sourceDomain.label
                                                    )
                                                  )
                                                ]
                                              )
                                            ]),
                                            _vm.sourceExportJob &&
                                            _vm.sourceExportJob.label
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "overline font-weight-black"
                                                    },
                                                    [_vm._v("Snapshot")]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "span",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.sourceExportJob
                                                            .label
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "span",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _c("job-status-icon", {
                                                        attrs: {
                                                          job:
                                                            _vm.sourceExportJob,
                                                          suffix:
                                                            _vm.formatNumber(
                                                              _vm.getTotalObjects(
                                                                _vm.sourceExportJob
                                                              )
                                                            ) + " objects at "
                                                        }
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.formatDbDate(
                                                              _vm
                                                                .sourceExportJob
                                                                .updatedAt
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ])
                                              : _vm._e()
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          attrs: {
                                            height: "100%",
                                            isLoading: _vm.isLoading
                                          }
                                        },
                                        [
                                          _c("v-card-title", [
                                            _vm._v("Destination")
                                          ]),
                                          _c("v-card-text", [
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "overline font-weight-black"
                                                },
                                                [_vm._v("Domain")]
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                { staticClass: "body-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.selectedDomain.label
                                                    )
                                                  )
                                                ]
                                              )
                                            ]),
                                            _vm.selectedExportJob &&
                                            _vm.selectedExportJob.label
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "overline font-weight-black"
                                                    },
                                                    [_vm._v("Snapshot")]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "span",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.selectedExportJob
                                                            .label
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "span",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _c("job-status-icon", {
                                                        attrs: {
                                                          job:
                                                            _vm.selectedExportJob,
                                                          suffix:
                                                            _vm.formatNumber(
                                                              _vm.getTotalObjects(
                                                                _vm.selectedExportJob
                                                              )
                                                            ) + " objects at "
                                                        }
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.formatDbDate(
                                                              _vm
                                                                .selectedExportJob
                                                                .updatedAt
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ])
                                              : _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "overline font-weight-black"
                                                    },
                                                    [_vm._v("Snapshot")]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "span",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _vm._v(
                                                        "This is not a Synchronized Import, so operations will be done on the Domain without comparing against a known state."
                                                      )
                                                    ]
                                                  )
                                                ])
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedLoadJob.type === "RESET"
                            ? _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          attrs: {
                                            height: "100%",
                                            loading: _vm.isLoading
                                          }
                                        },
                                        [
                                          _c("v-card-title", [
                                            _vm._v("Reset Domain")
                                          ]),
                                          _c("v-card-text", [
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "overline font-weight-black"
                                                },
                                                [_vm._v("Domain")]
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                { staticClass: "body-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.selectedDomain.label
                                                    )
                                                  )
                                                ]
                                              )
                                            ]),
                                            _vm.selectedExportJob &&
                                            _vm.selectedExportJob.label
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "overline font-weight-black"
                                                    },
                                                    [_vm._v("Snapshot")]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "span",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.selectedExportJob
                                                            .label
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "span",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _c("job-status-icon", {
                                                        attrs: {
                                                          job:
                                                            _vm.selectedExportJob,
                                                          suffix:
                                                            _vm.formatNumber(
                                                              _vm.getTotalObjects(
                                                                _vm.selectedExportJob
                                                              )
                                                            ) + " objects at "
                                                        }
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.formatDbDate(
                                                              _vm
                                                                .selectedExportJob
                                                                .updatedAt
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ])
                                              : _vm._e()
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedLoadJob.type === "RESTORE"
                            ? _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          attrs: {
                                            height: "100%",
                                            loading: _vm.isLoading
                                          }
                                        },
                                        [
                                          _c("v-card-title", [
                                            _vm._v("Source")
                                          ]),
                                          _c("v-card-subtitle", [
                                            _vm._v("Restore Point")
                                          ]),
                                          _c("v-card-text", [
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "overline font-weight-black"
                                                },
                                                [_vm._v("Domain")]
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                { staticClass: "body-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.sourceDomain.label
                                                    )
                                                  )
                                                ]
                                              )
                                            ]),
                                            _vm.sourceExportJob &&
                                            _vm.sourceExportJob.label
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "overline font-weight-black"
                                                    },
                                                    [_vm._v("Snapshot")]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "span",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.sourceExportJob
                                                            .label
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "span",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _c("job-status-icon", {
                                                        attrs: {
                                                          job:
                                                            _vm.sourceExportJob,
                                                          suffix:
                                                            _vm.formatNumber(
                                                              _vm.getTotalObjects(
                                                                _vm.sourceExportJob
                                                              )
                                                            ) + " objects at "
                                                        }
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.formatDbDate(
                                                              _vm
                                                                .sourceExportJob
                                                                .updatedAt
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ])
                                              : _vm._e()
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          attrs: {
                                            height: "100%",
                                            isLoading: _vm.isLoading
                                          }
                                        },
                                        [
                                          _c("v-card-title", [
                                            _vm._v("Destination")
                                          ]),
                                          _c("v-card-subtitle", [
                                            _vm._v("Current State")
                                          ]),
                                          _c("v-card-text", [
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "overline font-weight-black"
                                                },
                                                [_vm._v("Domain")]
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                { staticClass: "body-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.selectedDomain.label
                                                    )
                                                  )
                                                ]
                                              )
                                            ]),
                                            _vm.selectedExportJob &&
                                            _vm.selectedExportJob.label
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "overline font-weight-black"
                                                    },
                                                    [_vm._v("Snapshot")]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "span",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.selectedExportJob
                                                            .label
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "span",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _c("job-status-icon", {
                                                        attrs: {
                                                          job:
                                                            _vm.selectedExportJob,
                                                          suffix:
                                                            _vm.formatNumber(
                                                              _vm.getTotalObjects(
                                                                _vm.selectedExportJob
                                                              )
                                                            ) + " objects at "
                                                        }
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.formatDbDate(
                                                              _vm
                                                                .selectedExportJob
                                                                .updatedAt
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ])
                                              : _vm._e()
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-expansion-panels",
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        [
                                          _c("v-expansion-panel-header", [
                                            _vm._v(" Provisioning Comparison ")
                                          ]),
                                          _c(
                                            "v-expansion-panel-content",
                                            [
                                              _c(
                                                "import-job-detail-provisioning-comparison",
                                                {
                                                  attrs: {
                                                    sourceExportJob:
                                                      _vm.sourceExportJob,
                                                    sourceDomain:
                                                      _vm.sourceDomain,
                                                    destinationDomain:
                                                      _vm.selectedDomain
                                                  }
                                                }
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-expansion-panels",
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        [
                                          _c("v-expansion-panel-header", [
                                            _vm._v(" Advanced ")
                                          ]),
                                          _c(
                                            "v-expansion-panel-content",
                                            [
                                              _c("domo-operation-changer", {
                                                attrs: {
                                                  jobUuid: _vm.jobUuid,
                                                  summary: true
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function($event) {
                                      ;(_vm.migrationSetupStep = 2),
                                        (_vm.migrationSetupStepsCompleted = 1)
                                    }
                                  }
                                },
                                [_vm._v(" Continue ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: { complete: _vm.migrationSetupStep > 2, step: "2" }
                    },
                    [_c("div", [_vm._v("Review Loading Manifest")])]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "2" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c(
                              "div",
                              [
                                _vm._v(" The "),
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: _vm.downloadLoadJobManifestReport
                                    }
                                  },
                                  [_vm._v("loading manifest")]
                                ),
                                _vm.isDownloadingManifestReport
                                  ? _c("v-progress-circular", {
                                      staticClass: "mx-1 mb-1",
                                      attrs: {
                                        color: "primary",
                                        indeterminate: "",
                                        size: "15",
                                        width: "3"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " explains the sequence of operations that will be performed. Please review and check if everything looks correct. "
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function($event) {
                                      ;(_vm.migrationSetupStep = 3),
                                        (_vm.migrationSetupStepsCompleted = 2)
                                    }
                                  }
                                },
                                [_vm._v(" Continue ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { plain: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.migrationSetupStep = 1
                                    }
                                  }
                                },
                                [_vm._v(" Back ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: { complete: _vm.migrationSetupStep > 3, step: "3" }
                    },
                    [_vm._v(" Notifications ")]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "3" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("div", [
                              _vm._v(
                                " Fill out an email address to receive a notification when the job is complete. "
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-form",
                                {
                                  model: {
                                    value: _vm.validNotificationForm,
                                    callback: function($$v) {
                                      _vm.validNotificationForm = $$v
                                    },
                                    expression: "validNotificationForm"
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      rules: _vm.rules.email,
                                      type: "email",
                                      "prepend-icon": "mdi-email"
                                    },
                                    on: {
                                      keydown: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.preventDefault()
                                        return _vm.proceedWithNotificationEmail.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.notificationEmail,
                                      callback: function($$v) {
                                        _vm.notificationEmail = $$v
                                      },
                                      expression: "notificationEmail"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.validNotificationForm
                                  },
                                  on: {
                                    click: _vm.proceedWithNotificationEmail
                                  }
                                },
                                [_vm._v(" Continue ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { plain: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.migrationSetupStep = 2
                                    }
                                  }
                                },
                                [_vm._v(" Back ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _vm.isLoading
                                ? _c("v-progress-linear", {
                                    attrs: { indeterminate: "", value: 100 }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-stepper-step", { attrs: { step: "4" } }, [
                    _vm._v(" Run ")
                  ]),
                  _c("v-stepper-content", { attrs: { step: "4" } }, [
                    _vm.haveConfiguredEmail
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c("div", [
                                    _vm._v(
                                      " By running this job you agree to the operations listed. Proceeding will begin performing the operations on the destination domain. "
                                    )
                                  ])
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    !_vm.haveRunJob
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: { color: "primary" },
                                            on: {
                                              click:
                                                _vm.runLoadJobWithConfirmation
                                            }
                                          },
                                          [_vm._v(" Run ")]
                                        )
                                      : _vm._e(),
                                    _vm.haveRunJob
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: { color: "primary" },
                                            on: { click: _vm.goToLoadJobDetail }
                                          },
                                          [_vm._v(" Check Job Status ")]
                                        )
                                      : _vm._e(),
                                    !_vm.haveRunJob
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: { plain: "" },
                                            on: {
                                              click: function($event) {
                                                _vm.migrationSetupStep = 3
                                              }
                                            }
                                          },
                                          [_vm._v(" Back ")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _vm.isLoading
                                      ? _c("v-progress-linear", {
                                          attrs: {
                                            indeterminate: "",
                                            value: 100
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c("p", [_vm._v("Your Import Job is running.")]),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  title: "View the list of jobs"
                                },
                                on: { click: _vm.goToLoadJobList }
                              },
                              [_vm._v(" View Jobs ")]
                            )
                          ],
                          1
                        )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("confirm")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }